import React, { useState } from 'react';
import { IPassport } from "../tools/InterTools";

interface Props {
    passport: IPassport;
    onUpdate: (updatedPassport: IPassport) => void;
    onDelete: () => void;
    onClose: () => void;
}

const Passport: React.FC<Props> = ({ passport, onUpdate, onDelete, onClose }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedPassport, setEditedPassport] = useState({ ...passport });

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        onUpdate(editedPassport);
        setIsEditing(false);
    };

    // const handleCancelClick = () => {
    //     setIsEditing(false);
    //     setEditedPassport({ ...passport });
    // };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setEditedPassport((prevPassport) => ({
            ...prevPassport,
            [name]: value,
        }));
    };

    return (
        <div className="border rounded shadow-lg p-4">
            <h2 className="text-xl font-semibold mb-4">Record Details</h2>
            <div className="mb-4">
                <label className="block font-semibold">Name:</label>
                {isEditing ? (
                    <input
                        name="name"
                        value={editedPassport.name}
                        onChange={handleInputChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                ) : (
                    <span>{passport.name}</span>
                )}
            </div>
            <div className="mb-4">
                <label className="block font-semibold">Account:</label>
                {isEditing ? (
                    <input
                        name="account"
                        value={editedPassport.account}
                        onChange={handleInputChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                ) : (
                    <span>{passport.account}</span>
                )}
            </div>
            <div className="mb-4">
                <label className="block font-semibold">Password:</label>
                {isEditing ? (
                    <input
                        name="password"
                        value={editedPassport.password}
                        onChange={handleInputChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                ) : (
                    <span>{passport.password}</span>
                )}
            </div>
            <div className="flex space-x-4">
                {isEditing ? (
                    <button
                        onClick={handleSaveClick}
                        className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Save
                    </button>
                ) : (
                    <button
                        onClick={handleEditClick}
                        className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Edit
                    </button>
                )
                }
                <button
                    onClick={onClose}
                    className="btn bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                    Close
                </button>

                {!isEditing && (
                    <button
                        onClick={onDelete}
                        className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-auto"
                    >
                        Delete
                    </button>
                )}

            </div>
        </div>
    );
};

export default Passport;

import React, { useState } from 'react';
import { IPassport } from "../tools/InterTools";

interface Props {
    onAdd: (addPassport: IPassport) => void;
    onClose: () => void;
}

const AddPass: React.FC<Props> = ({ onAdd, onClose }) => {
    const [passport, setPassport] = useState<IPassport>({
        id: -1,
        name: "",
        account: "",
        password: "",
        updateTime: ""
    });

    const handleSaveClick = () => {
        onAdd(passport);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPassport((prevPassport) => ({
            ...prevPassport,
            [name]: value,
        }));
    };

    return (
        <div className="passport-details p-4 border rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Add Passport Entry</h2>
            <div className="mb-4">
                <label className="block text-gray-600">Name:</label>
                <input
                    className="border border-gray-300 p-2 rounded w-full"
                    type="text"
                    name="name"
                    value={passport.name}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-600">Account:</label>
                <input
                    className="border border-gray-300 p-2 rounded w-full"
                    type="text"
                    name="account"
                    value={passport.account}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-600">Password:</label>
                <input
                    className="border border-gray-300 p-2 rounded w-full"
                    type="password"
                    name="password"
                    value={passport.password}
                    onChange={handleInputChange}
                />
            </div>
            <div className="text-center">
                <button
                    className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSaveClick}
                >
                    Save
                </button>
            </div>
            <div className="text-center mt-2">
                <button
                    className="btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default AddPass;

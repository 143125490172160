import React, { useState } from 'react';
import { LoginRequest } from '../tools/NetworkTools';

function Login({ setLoggedIn, setUserData, setLoading }: any) {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        setLoading(true);
        const logData = await LoginRequest(user, password);
        if (typeof logData === 'string') {
            alert(logData);
            setLoading(false);
            return;
        }
        localStorage.setItem('login', JSON.stringify(logData));
        setUserData(logData);
        setLoggedIn(true);
        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
                    Welcome Back!
                </h2>
                <form>
                    <div className="mb-4">
                        <input
                            className="w-full px-4 py-3 rounded-lg border focus:outline-none focus:ring focus:border-blue-300"
                            type="text"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            placeholder="Username or email"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="w-full px-4 py-3 rounded-lg border focus:outline-none focus:ring focus:border-blue-300"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                        />
                    </div>
                    <button
                        className="w-full bg-blue-600 text-white rounded-lg py-3 font-semibold hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                        type="button"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;

import React, { useEffect, useState } from 'react';
import { IPassport, IUserData } from "../tools/InterTools";
import { ServiceRequest } from "../tools/NetworkTools";
import Passport from "./Passport";
import AddPass from "../pages/AddPass";

function PassportList({ userData, setUserData, setLoading, handleLogout }: any) {
    const [showAddPass, setShowAddPass] = useState(false);
    const [passports, setPassports] = useState<IPassport[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<IPassport | null>(null);

    useEffect(() => {
        const data: IUserData | undefined = userData;
        const allRecords = data ? data.passports : [];
        setPassports(allRecords);
    }, [userData]);

    const handleRefresh = async () => {
        setLoading(true);
        const uid = userData.uid;
        const token = userData.token;
        const logData = await ServiceRequest(uid, token, "get");
        if (typeof logData === "string") {
            alert(logData);
            setLoading(false);
            return;
        }
        setUserData(logData);
        setLoading(false);
    };

    const handleSave = async (passport: IPassport) => {
        setLoading(true);
        const uid = userData.uid;
        const token = userData.token;
        const logData = await ServiceRequest(uid, token, "set", [passport]);
        if (typeof logData === "string") alert(logData);
        setLoading(false);
    };

    const handleUpdate = async () => {
        setLoading(true);
        const uid = userData.uid;
        const token = userData.token;
        const logData = await ServiceRequest(uid, token, "set", passports);
        if (typeof logData === "string") alert(logData);
        setLoading(false);
    };

    const handleDelete = async () => {
        if (!selectedRecord) {
            alert("Please select a record to delete!");
            return;
        }
        setLoading(true);
        const uid = userData.uid;
        const token = userData.token;
        const logData = await ServiceRequest(uid, token, "delete", [selectedRecord]);
        if (typeof logData === "string") alert(logData);
        setLoading(false);
    };

    const handleAdd = () => {
        setShowAddPass(true); // Show the AddPass component
    };

    const handleRowClick = (record: IPassport) => {
        setSelectedRecord(record);
    };

    const handleClosePopup = () => {
        setSelectedRecord(null);
    };

    return (
        <div className="container mx-auto p-4">
            <div className="bg-gray-100 p-4 rounded shadow-lg">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">{userData.user}</h2>
                    <div className="space-x-4">
                        <button
                            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={handleAdd}
                        >
                            Add
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                        <button
                            className="bg-green-500 hover-bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={handleRefresh}
                        >
                            Refresh
                        </button>
                    </div>
                </div>

                <table className="table-auto w-full border-collapse border">
                    <thead>
                    <tr>
                        <th className="border px-2 py-2">S.No</th>
                        <th className="border px-4 py-2">Name</th>
                        <th className="border px-4 py-2">Account</th>
                        <th className="border px-4 py-2">Password</th>
                    </tr>
                    </thead>
                    <tbody>
                    {passports.map((passport, index) => (
                        <tr
                            key={index}
                            onClick={() => handleRowClick(passport)}
                            className="hover:bg-gray-200 cursor-pointer"
                        >
                            <td className="border px-2 py-2">{index}</td>
                            <td className="border px-4 py-2">{passport.name}</td>
                            <td className="border px-4 py-2">{passport.account}</td>
                            <td className="border px-4 py-2">*******</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {selectedRecord && (
                    <div className="mt-4">
                        <Passport
                            passport={selectedRecord}
                            onClose={handleClosePopup}
                            onDelete={handleDelete}
                            onUpdate={handleUpdate}
                        />
                    </div>
                )}

                {showAddPass && (
                    <div className="mt-4">
                        <AddPass onAdd={handleSave} onClose={handleClosePopup} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default PassportList;

import axios from "axios";
import {IPassport, IUserData} from "./InterTools";


export const HOST = "https://passport.usmanheart.net";

export const enum ApiPath {
    Login = "rapi/pass/login",
    Service = "rapi/pass/pass_service",
}

export async function LoginRequest(log_data: string, password: string|undefined=undefined, token: string|undefined=undefined) : Promise<IUserData | string> {
    // if(!log_data || !password) return "Information missing!";
    const uri = getURI(ApiPath.Login);
    const params = {
        token: token,
        data: log_data,
        cred: password
    }
    const data = await postRequest(uri, params);
    const msg = data.msg;
    if(msg !== "SUCCESS") return msg.toString();
    // return data.data;
    const resData = data.data;
    return  {
        user: log_data,
        uid: resData.hashed,
        token: resData.token,
        passports: resData.passports,
    }
}


export async function ServiceRequest(uid: number, token: string, ask: string, passports?: IPassport[]) : Promise<IUserData | string> {
    const uri = getURI(ApiPath.Service);
    const params = {
        uid: uid,
        ask: ask,
        cred: token,
        passports: passports,
    }
    const data = await postRequest(uri, params);
    if(typeof data === "string") return data;
    if (ask === "get") return data.data;
    return data.msg.toString();
}

async function postRequest(uri: string, params: Object) : Promise<any> {
    const info = await axios.post(uri, params);
    if(!info) return "Network error!";
    const data = info.data;
    if(!data) return "Network error!";
    return data;
    // const msg = data.msg;
    // if(msg !== "SUCCESS") return msg.toString();
    // return data.data;
}


function getURI(apiPath: ApiPath) : string {
    switch (apiPath) {
        case ApiPath.Login: return `${HOST}/${ApiPath.Login}`;
        case ApiPath.Service: return `${HOST}/${ApiPath.Service}`;
        default: return ``;
    }
}
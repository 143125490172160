import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {IUserData} from "./InterTools";
import {LoginRequest} from "./NetworkTools";

export function InitUserData(): [IUserData,  Dispatch<SetStateAction<IUserData>>] {
    const userData = {
        uid: -1,
        token: "",
        passports: []
    }
    const loadedData: IUserData = JSON.parse(JSON.stringify(userData));
    return useState(loadedData);
}

export function LoadUser(loggedIn: boolean, setLoggedIn: Dispatch<boolean>, setUserData: Dispatch<SetStateAction<IUserData>>) : boolean {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const loadPost = async () => {
            if (loggedIn) {
                setLoading(false);
                return;
            }
            setLoading(true);
            const userInfo = localStorage.getItem('login');
            if (!userInfo) {
                setLoading(false);
                return;
            }
            const loginInfo: IUserData = JSON.parse(userInfo);
            const token = loginInfo.token;
            if (!token) {
                setLoading(false);
                return;
            }
            const user = loginInfo.user;
            const logData = await LoginRequest(user, undefined, token);
            if (typeof logData === "string") {
                alert(logData);
                setLoading(false);
                return;
            }
            localStorage.setItem("login", JSON.stringify(logData));
            setUserData(logData);
            setLoggedIn(true);
            setLoading(false);
        }

        loadPost().then(r => { return });
    }, [loggedIn, setLoggedIn, setUserData]);
    return loading;
}
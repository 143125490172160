import './index.css'
import Login from "./pages/Login";
import React, {useEffect, useState} from 'react';
import PassportList from "./components/PassportList";
import {InitUserData, LoadUser} from "./tools/UserTools";

function App() {
  let [userData, setUserData] = InitUserData();
  let [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const loadingUser = LoadUser(loggedIn, setLoggedIn, setUserData);

  useEffect(() => {

  }, []);

  const handleLogout = () => {
    localStorage.removeItem('login');
    setLoggedIn(false);
  };

  return (
      <div className="App">
        <header className="App-header">

          {loading || loadingUser ? (
              <div>
                Loading...
              </div>
          ) :
          <div>
            {loggedIn ? (
                <div>
                  <PassportList
                      userData={userData}
                      handleLogout={handleLogout}
                      setUserData={setUserData}
                      setLoading={setLoading}
                  />
                </div>
            ) : (
                <Login setUserData={setUserData} setLoggedIn={setLoggedIn} setLoading={setLoading} />
            )}
          </div>
          }

        </header>
      </div>
  );
}

export default App;
